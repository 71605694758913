import type {} from "@mui/lab/themeAugmentation";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { ptBR as DataGridPtBR } from "@mui/x-data-grid";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    ai: true;
  }
}

export const colors = {
  grey: "#5E5873",
  aiLinearGradient: "linear-gradient(90deg, #0307ff, #9a03ff)", // #710ccf
};

export const themeDefault = createTheme(
  {
    palette: {
      primary: {
        main: "#193E5B",
      },
      secondary: {
        main: "#D9D9D9",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      h1: {
        fontWeight: "bold",
      },
      h2: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
      },
      h4: {
        fontWeight: "bold",
      },
      h5: {
        fontWeight: "bold",
      },
      h6: {
        fontWeight: "bold",
      },
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        variants: [
          {
            props: { variant: "ai" },
            style: {
              background: "linear-gradient(90deg, #0307ff, #9a03ff)",
              color: "#ffffff",
            },
          },
        ],
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            "&.MuiButton-ai .MuiLoadingButton-loadingIndicator": {
              color: "#ffffff",
            },
            "&.MuiButton-ai.Mui-disabled:not(.MuiLoadingButton-loading)": {
              color: "#ffffff80",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          sizeSmall: {
            fontSize: "15px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          sizeSmall: {
            fontSize: "15px",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            "&::first-letter": {
              textTransform: "uppercase",
            },
          },
        },
      },
    },
  },
  ptBR,
  DataGridPtBR
);
