import { createSlice } from "@reduxjs/toolkit";
import {
  createJob,
  destroyJob,
  getJobs,
  getPipelines,
  publishJob,
  showJob,
  updateStatus,
  updateJob,
} from "./jobActions";
import { RootState } from "../../app/store";
import {
  JobParams,
  JobResponse,
  JobShowResponse,
  JobIndexAttributes,
  JobPipelinesAttributes,
} from "./jobApi";
import filterErrorMessage from "../../utils/filterErrorMessage";
import { RestrictedRecord } from "../../interfaces";

export interface initialState {
  initialValues: JobParams;
  job: JobResponse | JobShowResponse | null;
  jobs: JobIndexAttributes[];
  pipelines: JobPipelinesAttributes[];
  isFetching: boolean;
  isFetchingPipelines: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isDestroying: boolean;
  isPublishing: boolean;
  isUpdatingStatus: boolean;
  isSuccess: boolean;
  isPipelinesSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isDestroyingSuccess: boolean;
  isPublishingSuccess: boolean;
  isUpdatingStatusSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
  restrictedRecords: RestrictedRecord[];
}

export const initialJobValues: JobParams = {
  id: null,
  name: "",
  status: "draft",
  working_type: null,
  working_type_visible: false,
  working_model: null,
  state: "",
  city: "",
  description: "",
  step: 0,
  internal_id: "",
  is_pwd: false,
  minimum_salary_range: null,
  maximum_salary_range: null,
  salary_range_visible: false,
  department_visible: false,
  department: null,
  department_id: null,
  requirements: [],
  requirements_visible: false,
  benefits: [],
  benefit_ids: [],
  benefits_visible: false,
  users: [],
  user_ids: [],
  form: {
    template: "simplified_registration",
    fields: {
      resume: "required",
      phone_number: "off",
      address: "off",
      avatar: "off",
      educations: "off",
      work_experiences: "off",
      summary: "off",
      cover_letter: "off",
      linkedin: "off",
      instagram: "off",
      tiktok: "off",
      github: "off",
      facebook: "off",
    },
    questions: [],
  },
  job_pipelines_attributes: [
    {
      id: null,
      name: "Inscritos",
      order: -1,
      static: true,
    },
    {
      id: null,
      name: "Contratados",
      order: 9998,
      static: true,
    },
    {
      id: null,
      name: "Excluídos",
      order: 9999,
      static: true,
    },
  ],
};

export const jobSlice = createSlice({
  name: "job",
  initialState: {
    initialValues: initialJobValues,
    job: null,
    jobs: [],
    pipelines: [],
    isFetching: false,
    isFetchingPipelines: false,
    isSaving: false,
    isFinding: false,
    isDestroying: false,
    isPublishing: false,
    isUpdatingStatus: false,
    isSuccess: false,
    isPipelinesSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isDestroyingSuccess: false,
    isPublishingSuccess: false,
    isUpdatingStatusSuccess: false,
    isError: false,
    errorMessage: null,
    restrictedRecords: [],
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isDestroying = false;
      state.isPublishing = false;
      state.isUpdatingStatus = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isDestroyingSuccess = false;
      state.isPublishingSuccess = false;
      state.isUpdatingStatusSuccess = false;
      state.isError = false;
      state.errorMessage = null;
      state.restrictedRecords = [];
    },
    clearShowState: (state) => {
      state.job = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
      state.restrictedRecords = [];
    },
    clearDestroyState: (state) => {
      state.job = null;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
      state.restrictedRecords = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getJobs.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.jobs = payload.records;
    });
    builder.addCase(getJobs.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.jobs = [];
    });

    builder.addCase(createJob.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createJob.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.job = payload;
    });
    builder.addCase(createJob.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(showJob.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showJob.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.job = payload;
    });
    builder.addCase(showJob.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateJob.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateJob.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.job = payload;
    });
    builder.addCase(updateJob.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
      state.restrictedRecords = payload?.restricted_records || [];
    });

    builder.addCase(destroyJob.pending, (state) => {
      state.isDestroying = true;
      state.isDestroyingSuccess = false;
    });
    builder.addCase(destroyJob.fulfilled, (state) => {
      state.isDestroying = false;
      state.isDestroyingSuccess = true;
    });
    builder.addCase(destroyJob.rejected, (state, action) => {
      state.isDestroying = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(publishJob.pending, (state) => {
      state.isPublishing = true;
      state.isPublishingSuccess = false;
    });
    builder.addCase(publishJob.fulfilled, (state, { payload }) => {
      state.isPublishing = false;
      state.isPublishingSuccess = true;
      state.job = payload;
    });
    builder.addCase(publishJob.rejected, (state, action) => {
      state.isPublishing = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.isUpdatingStatus = true;
      state.isUpdatingStatusSuccess = false;
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
      state.isUpdatingStatus = false;
      state.isUpdatingStatusSuccess = true;
      state.job = payload;
    });
    builder.addCase(updateStatus.rejected, (state, action) => {
      state.isUpdatingStatus = false;
      state.isError = true;
      const payload = action.payload as JobResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(getPipelines.pending, (state) => {
      state.isFetchingPipelines = true;
    });
    builder.addCase(getPipelines.fulfilled, (state, { payload }) => {
      state.isFetchingPipelines = false;
      state.isPipelinesSuccess = true;
      state.pipelines = payload.records;
    });
    builder.addCase(getPipelines.rejected, (state, action) => {
      state.isFetchingPipelines = false;
      state.isError = true;
      state.pipelines = [];
    });
  },
});

export const { clearState, clearShowState, clearDestroyState } =
  jobSlice.actions;

export const jobSelector = (state: RootState) => state.job;
