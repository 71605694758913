import { createSlice } from "@reduxjs/toolkit";
import {
  authenticate,
  fetchUserByToken,
  getUsers,
  logout,
  showUser,
  signUp,
  updateUser,
  transferOwnership,
  inviteUser,
  reinviteUser,
  getInvitation,
  acceptInvitation,
  updateMyCurrentCompany,
  updateCandidate,
} from "./userActions";
import { RootState } from "../../app/store";
import {
  AuthenticateResponse,
  UserGetInvitationResponse,
  UserIndexAttributes,
  UserInviteResponse,
  UserReinviteResponse,
  UserResponse,
  UserShowResponse,
} from "./userApi";
import filterErrorMessage from "../../utils/filterErrorMessage";
import { State, StripeSubscriptionStatus } from "../../utils/types";
import { ResponseWithErrors } from "../../interfaces";
import { Education } from "../../components/MultiInput/Education";
import { WorkExperience } from "../../components/MultiInput/WorkExperience";

export interface UserAttributes extends ResponseWithErrors {
  id: number | null;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  company: {
    id: number;
    slug: string;
    name: string;
    logo: string | null;
    stripe_subscription_status: StripeSubscriptionStatus;
    stripe_plan: string | null;
    owner: {
      id: number;
      email: string;
    };
    plan: {
      key: string;
      features: {
        [featureName: string]: number;
      };
    };
    total_ai_usages: number;
  } | null;
  is_owner: boolean;
  is_candidate: boolean;
  created_at: string;
  permission_names: Array<string>;
  companies: {
    id: number;
    name: string;
    slug: string;
    logo: string | null;
  }[];
  avatar: string | null;
  resume: {
    name: string;
    url: string;
  } | null;
  phone_number: string | null;
  cep: string | null;
  address_1: string | null;
  address_2: string | null;
  district: string | null;
  city: string | null;
  state: State;
  summary: string | null;
  cover_letter: string | null;
  linkedin: string | null;
  instagram: string | null;
  tiktok: string | null;
  github: string | null;
  facebook: string | null;
  educations: Education[];
  work_experiences: WorkExperience[];
}

interface initialState {
  currentUser: UserAttributes;
  user: UserResponse | UserShowResponse | null;
  users: UserIndexAttributes[];
  invitation: UserGetInvitationResponse | null;
  isFetching: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isReinviting: boolean;
  isFetchingInvitation: boolean;
  isAcceptingInvitation: boolean;
  isSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isReinviteSuccess: boolean;
  isFetchingInvitationSuccess: boolean;
  isAcceptingInvitationSuccess: boolean;
  isUnauthorized: boolean;
  isError: boolean;
  errorMessage: string | null;
}

const initialCurrentUserValue: UserAttributes = {
  id: null,
  name: "",
  first_name: "",
  last_name: "",
  email: "",
  company: null,
  is_owner: false,
  is_candidate: false,
  created_at: "",
  permission_names: [],
  companies: [],
  avatar: null,
  resume: null,
  phone_number: null,
  cep: null,
  address_1: null,
  address_2: null,
  district: null,
  city: null,
  state: null,
  summary: null,
  cover_letter: null,
  linkedin: null,
  instagram: null,
  tiktok: null,
  github: null,
  facebook: null,
  educations: [],
  work_experiences: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: initialCurrentUserValue,
    user: null,
    users: [],
    invitation: null,
    isFetching: false,
    isLoading: false,
    isSaving: false,
    isFinding: false,
    isReinviting: false,
    isFetchingInvitation: false,
    isAcceptingInvitation: false,
    isSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isReinviteSuccess: false,
    isFetchingInvitationSuccess: false,
    isAcceptingInvitationSuccess: false,
    isUnauthorized: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isLoading = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isReinviting = false;
      state.isFetchingInvitation = false;
      state.isAcceptingInvitation = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isReinviteSuccess = false;
      state.isAcceptingInvitationSuccess = false;
      state.isUnauthorized = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.user = null;
      state.invitation = null;
      state.isFindingSuccess = false;
      state.isFetchingInvitationSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.users = payload.records;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.users = [];
    });

    builder.addCase(showUser.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showUser.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.user = payload;
    });
    builder.addCase(showUser.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as UserResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });

    builder.addCase(updateUser.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.user = payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as UserResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(inviteUser.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(inviteUser.fulfilled, (state) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
    });
    builder.addCase(inviteUser.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as UserInviteResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(reinviteUser.pending, (state) => {
      state.isReinviting = true;
      state.isReinviteSuccess = false;
    });
    builder.addCase(reinviteUser.fulfilled, (state) => {
      state.isReinviting = false;
      state.isReinviteSuccess = true;
    });
    builder.addCase(reinviteUser.rejected, (state, action) => {
      state.isReinviting = false;
      state.isError = true;
      const payload = action.payload as UserReinviteResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(getInvitation.pending, (state) => {
      state.isFetchingInvitation = true;
      state.isFetchingInvitationSuccess = false;
    });
    builder.addCase(getInvitation.fulfilled, (state, { payload }) => {
      state.isFetchingInvitation = false;
      state.isFetchingInvitationSuccess = true;
      state.invitation = payload;
    });
    builder.addCase(getInvitation.rejected, (state, action) => {
      state.isFetchingInvitation = false;
      state.isError = true;
      const payload = action.payload as UserGetInvitationResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(acceptInvitation.pending, (state) => {
      state.isAcceptingInvitation = true;
      state.isAcceptingInvitationSuccess = false;
    });
    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.isAcceptingInvitation = false;
      state.isAcceptingInvitationSuccess = true;
    });
    builder.addCase(acceptInvitation.rejected, (state, action) => {
      state.isAcceptingInvitation = false;
      state.isError = true;
      const payload = action.payload as UserGetInvitationResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(authenticate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(authenticate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isUnauthorized = false;
      state.currentUser = payload;
    });
    builder.addCase(authenticate.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      const payload = action.payload as AuthenticateResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(fetchUserByToken.pending, (state) => {
      state.isFinding = true;
    });
    builder.addCase(fetchUserByToken.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.currentUser = payload;
      state.isUnauthorized = false;
      state.isSuccess = true;
    });
    builder.addCase(fetchUserByToken.rejected, (state) => {
      state.isFinding = false;
      state.isUnauthorized = true;
      state.currentUser = initialCurrentUserValue;
    });

    builder.addCase(updateMyCurrentCompany.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateMyCurrentCompany.fulfilled, (state) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
    });
    builder.addCase(updateMyCurrentCompany.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
    });

    builder.addCase(transferOwnership.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(transferOwnership.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.currentUser = payload;
    });
    builder.addCase(transferOwnership.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as UserResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(updateCandidate.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateCandidate.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.currentUser = payload;
    });
    builder.addCase(updateCandidate.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as UserResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.currentUser = initialCurrentUserValue;
    });
    builder.addCase(logout.rejected, (state) => {
      state.currentUser = initialCurrentUserValue;
    });

    builder.addCase(signUp.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as AuthenticateResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });
  },
});

export const { clearShowState, clearState } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;
