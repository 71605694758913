import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormikProps, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  createJob,
  destroyJob,
  publishJob,
  showJob,
  updateStatus,
  updateJob,
} from "../../redux/job/jobActions";
import {
  clearDestroyState,
  clearShowState,
  clearState,
  initialJobValues,
  jobSelector,
} from "../../redux/job/jobSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Backdrop from "../../components/Backdrop";
import { JobParams, JobResponse, newJob } from "../../redux/job/jobApi";
import { formatCurrency, formatDate } from "../../utils/format";
import Stepper, { Steps } from "../../components/Stepper";
import JobDetails from "./JobDetails";
import JobForms from "./JobForms";
import JobProcess from "./JobProcess";
import JobPromotion from "./JobPromotion";
import { maskCurrency } from "../../utils/masks";
import { Close } from "@mui/icons-material";
import IFrame from "../../components/IFrame";
import Job from "../CompanyPage/Job";
import { companySelector } from "../../redux/company/companySlice";
import Sticky from "../../components/Sticky";
import { showCompany } from "../../redux/company/companyActions";
import { userSelector } from "../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import urls from "../../utils/urls";
import usePermission from "../../hooks/usePermission";
import { texts } from "../../utils/texts";
import statusCodes from "../../utils/statusCodes";
import Select from "../../components/Select";
import { jobStatusesOptions } from "../../utils/constants";
import { JobStatus } from "../../utils/types";
import JobDescription from "./JobDescription";

interface FieldsTouched {
  [k: string]: boolean;
}

const steps: Steps[] = [
  {
    name: "Detalhes",
    fields: [
      "name",
      "working_type",
      "working_model",
      "state",
      "city",
      "internal_id",
      "is_pwd",
      "minimum_salary_range",
      "maximum_salary_range",
      "salary_range_visible",
      "department_visible",
      "department_id",
    ],
    requiredFields: ["name", "working_type", "working_model"],
  },
  {
    name: "Descrição",
    fields: ["description"],
    requiredFields: ["description"],
  },
  {
    name: "Formulário",
    fields: ["form"],
    requiredFields: ["form"],
    onLeaveCurrentStep: (formik: FormikProps<JobParams>) => {
      const questions = formik.values.form.questions;
      formik.setFieldValue(
        "form.questions",
        questions.filter((question) => question.name !== "")
      );
    },
  },
  {
    name: "Funil de Recrutamento",
    fields: ["job_pipelines_attributes"],
    requiredFields: ["job_pipelines_attributes"],
    onLeaveCurrentStep: (formik: FormikProps<JobParams>) => {
      const pipelines = formik.values.job_pipelines_attributes;
      formik.setFieldValue(
        "job_pipelines_attributes",
        pipelines.filter((pipeline) => pipeline.name !== "")
      );
    },
  },
  {
    name: "Divulgação",
    fields: [],
    requiredFields: [],
  },
];

function ManageJobs() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const hasPermissionToJobsCreate = usePermission("jobs.create");
  const hasPermissionToJobsUpdate = usePermission("jobs.update");
  const hasPermissionToJobsDestroy = usePermission("jobs.destroy");

  const [activeStep, setActiveStep] = useState<number>(0);
  const [preview, setPreview] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const jobState = useAppSelector(jobSelector);
  const companyState = useAppSelector(companySelector);
  const userState = useAppSelector(userSelector);

  useEffect(() => {
    if (userState.currentUser.company?.id && !companyState.company) {
      dispatch(showCompany(userState.currentUser.company.id.toString()));
    }
  }, [userState.currentUser.company?.id, companyState.company]);

  useEffect(() => {
    if (id) {
      dispatch(showJob(id)).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          const payload = value.payload as JobResponse;
          let step = payload.step || 0;
          if (payload.status === "published") {
            step = lastStepIndex();
          }
          updateActiveStep(step);
        } else {
          navigate(urls.jobs);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const fetchNewJob = async () => {
      if (!id) {
        const response = await newJob();
        if (response.status === statusCodes.ok) {
          formik.setFieldValue("users", response.data.users);
          formik.setFieldValue(
            "user_ids",
            response.data.users.map((user) => user.id)
          );
        }
      }
    };

    fetchNewJob();
  }, [id]);

  useEffect(() => {
    if (jobState.errorMessage) {
      toast.error(jobState.errorMessage);
    }
  }, [jobState.errorMessage]);

  useEffect(() => {
    if (jobState.restrictedRecords) {
      jobState.restrictedRecords.map((restrictedRecord) => {
        restrictedRecord.ids.map((restrictedRecordId) => {
          const field = formik.values?.[
            restrictedRecord.field as keyof JobParams
          ] as any;
          if (Array.isArray(field)) {
            const index = field.findIndex(
              (value: any) => value?.id === restrictedRecordId
            );
            formik.setFieldValue(
              `${restrictedRecord.field}.${index}._destroy`,
              false
            );
          }
        });
      });
    }
  }, [jobState.restrictedRecords]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(clearShowState());
      dispatch(clearDestroyState());
    };
  }, []);

  useEffect(() => {
    if (jobState.isPublishingSuccess) {
      toast.success("Vaga publicada com sucesso");
    }
  }, [jobState.isPublishingSuccess]);

  const formik = useFormik({
    initialValues: jobState.job
      ? {
          id: jobState.job.id,
          name: jobState.job.name,
          status: jobState.job.status,
          working_type: jobState.job.working_type,
          working_type_visible: jobState.job.working_type_visible,
          working_model: jobState.job.working_model,
          state: jobState.job.state,
          city: jobState.job.city,
          description: jobState.job.description,
          step: jobState.job.step,
          internal_id: jobState.job.internal_id,
          is_pwd: jobState.job.is_pwd,
          minimum_salary_range: jobState.job.minimum_salary_range
            ? maskCurrency(
                parseFloat(jobState.job.minimum_salary_range)
                  .toFixed(2)
                  .toString()
              )
            : null,
          maximum_salary_range: jobState.job.maximum_salary_range
            ? maskCurrency(
                parseFloat(jobState.job.maximum_salary_range)
                  .toFixed(2)
                  .toString()
              )
            : null,
          salary_range_visible: jobState.job.salary_range_visible,
          department_visible: jobState.job.department_visible,
          department: jobState.job.department,
          department_id: jobState.job.department?.id || null,
          requirements: jobState.job.requirements,
          requirements_visible: jobState.job.requirements_visible,
          benefits: jobState.job.benefits,
          benefit_ids: jobState.job.benefits.map((benefit) => benefit.id),
          benefits_visible: jobState.job.benefits_visible,
          users: jobState.job.users,
          user_ids: jobState.job.users.map((user) => user.id),
          form: jobState.job.form,
          owner: jobState.job.owner,
          job_pipelines_attributes: jobState.job.job_pipelines,
        }
      : initialJobValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const saveJob = async (nextStep = false): Promise<JobResponse> => {
    setAllFieldsTouched();
    const step = nextStep ? activeStep + 1 : activeStep;
    const formattedValues = formatValues();

    const params = {
      ...formik.values,
      ...formattedValues,
      step,
    };

    let action;

    if (formik.values.id) {
      action = updateJob({ params, formikBag: formik });
    } else {
      action = createJob({ params, formikBag: formik });
    }

    const result = await dispatch(action);

    if (result.meta.requestStatus !== "rejected") {
      if (nextStep) {
        updateActiveStep(step);
      } else {
        toast.success("Vaga salva com sucesso");
      }
    }

    return result.payload as JobResponse;
  };

  const publish = () => {
    if (formik.values.id) {
      dispatch(updateJob({ params: formik.values, formikBag: formik })).then(
        (value) => {
          if (value.meta.requestStatus !== "rejected") {
            const payload = value.payload as JobResponse;
            dispatch(
              publishJob({ id: payload.id.toString(), formikBag: formik })
            );
          }
        }
      );
    }
  };

  const updateStatusJob = (status: JobStatus) => {
    if (formik.values.id) {
      dispatch(updateJob({ params: formik.values, formikBag: formik })).then(
        (value) => {
          if (value.meta.requestStatus !== "rejected") {
            const payload = value.payload as JobResponse;
            dispatch(
              updateStatus({
                id: payload.id.toString(),
                status,
                formikBag: formik,
              })
            );
          }
        }
      );
    }
  };

  const formatValues = () => {
    let minimumSalaryRange = formik.values.minimum_salary_range;
    if (minimumSalaryRange) {
      minimumSalaryRange = formatCurrency(minimumSalaryRange);
    }
    let maximumSalaryRange = formik.values.maximum_salary_range;
    if (maximumSalaryRange) {
      maximumSalaryRange = formatCurrency(maximumSalaryRange);
    }
    let departmentId = null;
    if (formik.values.department) {
      departmentId = formik.values.department.id;
    }
    let benefitIds = null;
    if (formik.values.benefits) {
      benefitIds = formik.values.benefits.map((benefit) => benefit.id);
    }
    let userIds = null;
    if (formik.values.users) {
      userIds = formik.values.users
        .filter((user) => !user?.disabled)
        .map((user) => user.id);
    }

    return {
      minimum_salary_range: minimumSalaryRange,
      maximum_salary_range: maximumSalaryRange,
      department_id: departmentId,
      benefit_ids: benefitIds || [],
      user_ids: userIds || [],
    };
  };

  const setAllFieldsTouched = () => {
    const allFieldsTouched: FieldsTouched = {};
    Object.keys(formik.values).forEach((field) => {
      allFieldsTouched[field] = true;
    });

    formik.setTouched(allFieldsTouched);
  };

  const updateActiveStep = (step: number) => {
    setActiveStep(step);
  };

  const lastStepIndex = () => {
    return steps.length - 1;
  };

  const isLastStep = useMemo(() => {
    return lastStepIndex() === activeStep;
  }, [activeStep]);

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    if (jobState.job?.id) {
      await dispatch(destroyJob(jobState.job.id.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Vaga excluída com sucesso");
          navigate(urls.jobs);
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const renderStepItems = () => {
    switch (activeStep) {
      case 0:
        return <JobDetails formik={formik} saveJob={saveJob} />;
      case 1:
        return <JobDescription formik={formik} />;
      case 2:
        return <JobForms formik={formik} />;
      case 3:
        return <JobProcess formik={formik} />;
      case 4:
        return <JobPromotion formik={formik} />;
    }
  };

  const noHasPermissionToCreateOrUpdate =
    (jobState.job?.id && !hasPermissionToJobsUpdate) ||
    (!jobState.job?.id && !hasPermissionToJobsCreate);

  return (
    <Box>
      {jobState.isFinding && <Backdrop />}
      <Typography variant="h5">
        {id ? "Atualizar vaga" : "Cadastrar novo vaga"}
      </Typography>
      <Paper sx={{ mb: 2, mt: 2, p: { xs: 1, sm: 2 } }}>
        {(jobState.job || !id) && (
          <Stepper
            steps={steps}
            nonLinear
            alternativeLabel
            onStepChange={(step) => updateActiveStep(step)}
            activeStep={activeStep}
            stepSaved={formik.values.step}
            formik={formik}
            sx={{ pt: 1 }}
          />
        )}

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={9}>
            {renderStepItems()}
          </Grid>
          <Grid item xs={12} md={3} sx={{ position: "relative" }}>
            <Sticky offsetTop={195}>
              {formik.values.status !== "draft" && (
                <FormGroup sx={{ mb: 2 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="status-label"
                      id="status"
                      label="Status"
                      size="small"
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        updateStatusJob(e.target.value as JobStatus);
                      }}
                      value={formik.values.status}
                      disabled={!hasPermissionToJobsUpdate}
                    >
                      {jobStatusesOptions.map((jobStatusesOption) => (
                        <MenuItem
                          key={jobStatusesOption.id}
                          value={jobStatusesOption.id}
                        >
                          {jobStatusesOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              )}
              {jobState.job?.owner && (
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: 14 }}>
                    Criado por {jobState.job.owner.name}
                  </Typography>
                </Box>
              )}
              {formik.values.status === "published" &&
                jobState.job?.published_at && (
                  <Box sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: 14 }}>
                      Publicado às {formatDate(jobState.job.published_at, true)}
                    </Typography>
                  </Box>
                )}
              {formik.values.status !== "draft" && (
                <Tooltip
                  title={
                    noHasPermissionToCreateOrUpdate ? texts.notAuthorized : ""
                  }
                  sx={{ mb: 2 }}
                >
                  <Box>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      size="large"
                      fullWidth
                      onClick={() => saveJob()}
                      loading={jobState.isSaving}
                      disabled={noHasPermissionToCreateOrUpdate}
                    >
                      Atualizar
                    </LoadingButton>
                  </Box>
                </Tooltip>
              )}
              {!isLastStep && (
                <Tooltip
                  title={
                    noHasPermissionToCreateOrUpdate ? texts.notAuthorized : ""
                  }
                  sx={{ mb: 2 }}
                >
                  <Box>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      size="large"
                      fullWidth
                      onClick={() => saveJob(true)}
                      disabled={noHasPermissionToCreateOrUpdate}
                    >
                      Próxima Etapa
                    </LoadingButton>
                  </Box>
                </Tooltip>
              )}
              {formik.values.status === "draft" && (
                <>
                  {isLastStep && (
                    <Tooltip
                      title={
                        !hasPermissionToJobsUpdate ? texts.notAuthorized : ""
                      }
                      sx={{ mb: 2 }}
                    >
                      <Box>
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          size="large"
                          fullWidth
                          onClick={() => publish()}
                          loading={jobState.isPublishing}
                          disabled={!hasPermissionToJobsUpdate}
                        >
                          Publicar
                        </LoadingButton>
                      </Box>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      noHasPermissionToCreateOrUpdate ? texts.notAuthorized : ""
                    }
                    sx={{ mb: 2 }}
                  >
                    <Box>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        size="large"
                        fullWidth
                        onClick={() => saveJob()}
                        loading={jobState.isSaving}
                        disabled={noHasPermissionToCreateOrUpdate}
                      >
                        Salvar Rascunho
                      </LoadingButton>
                    </Box>
                  </Tooltip>
                </>
              )}
              {[0, 1, 2].includes(activeStep) && (
                <>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    size="large"
                    fullWidth
                    onClick={() => setPreview(true)}
                    sx={{ mb: 2 }}
                  >
                    Pré-visualizar
                  </LoadingButton>
                  <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={preview}
                    onClose={() => setPreview(false)}
                  >
                    <DialogTitle>Pré-visualização</DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={() => setPreview(false)}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Close />
                    </IconButton>
                    <IFrame>
                      {[0, 1].includes(activeStep) ? (
                        <Job
                          isPreview
                          previewData={{
                            ...formik.values,
                            published_at: jobState.job?.published_at || null,
                            company: {
                              logo: companyState.company?.logo || null,
                              banner: companyState.company?.banner || null,
                              name: companyState.company?.name || null,
                              slogan: companyState.company?.slogan || null,
                              primary_color:
                                companyState.company?.primary_color || null,
                            },
                          }}
                        />
                      ) : null}
                    </IFrame>
                  </Dialog>
                </>
              )}
              {jobState.job?.id && (
                <>
                  <Tooltip
                    title={
                      !hasPermissionToJobsDestroy ? texts.notAuthorized : ""
                    }
                    sx={{ mb: 2 }}
                  >
                    <Box>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        size="large"
                        color="error"
                        fullWidth
                        onClick={() => handleOpenDeleteDialog()}
                        disabled={!hasPermissionToJobsDestroy}
                      >
                        Excluir
                      </LoadingButton>
                    </Box>
                  </Tooltip>
                  <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                  >
                    <DialogTitle>Confirmar exclusão</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Tem certeza de que deseja excluir esta vaga?{" "}
                        {jobState.job?.candidates_count > 0
                          ? `Há ${jobState.job.candidates_count} candidato${
                              jobState.job.candidates_count > 1 ? "s" : ""
                            } cadastrado${
                              jobState.job.candidates_count > 1 ? "s" : ""
                            } nessa vaga`
                          : null}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        onClick={handleCloseDeleteDialog}
                      >
                        Cancelar
                      </Button>
                      <LoadingButton
                        variant="contained"
                        onClick={handleDelete}
                        color="error"
                        loading={jobState.isDestroying}
                      >
                        Excluir
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Sticky>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ManageJobs;
